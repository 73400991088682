<template>
  <Content>
    <template #contentTitle>
      {{ $t("menu.consignment_order_list") }}
    </template>
    <template v-slot:contentBody="wrap">
      <a-row :gutter="[16, 16]" type="flex" justify="space-between" align="bottom">
        <a-col flex="1">
          <a-row :gutter="[16, 16]">
            <a-col>
              <a-input style="width: 250px" v-model:value="searchKey" :placeholder="$t('logistics.placeholder_order_recipients')" allow-clear />
            </a-col>
            <a-col>
              <a-select v-model:value="selectedConsignmentNo"
                        optionFilterProp="search-key"
                        show-search
                        allow-clear
                        :placeholder="$t('logistics.consignment_warehouse_no')"
                        style="width: 250px">
                <a-select-option v-for="(item,index) in productWarehouseStatus"
                                 :key="index"
                                 :value="item.value"
                                 :title="`${item.text1}(${item.text})`"
                                 :search-key="item.text1 + item.text">
                  {{item.text1}}({{item.text}})
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-select v-model:value="selectedCountrys"
                        :allowClear="true"
                        :placeholder="$t('common.destination_country')"
                        optionFilterProp="search-key"
                        show-search
                        style="width: 250px;">
                <a-select-option v-for="item in countrys"
                                 :key="item.id"
                                 :search-key="item.cnName + item.enName+item.ioS2"
                                 :title="getLanguageName(item)+'('+item.ioS2+')'">
                  {{getLanguageName(item)}}({{item.ioS2}})
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-select v-model:value="logisticsType"
                        allow-clear
                        :placeholder="$t('warehouse.logistics_way')"
                        style="width: 250px;">
                <a-select-option v-for="(value, index) in agentOrderLogisticsTypeEnum"
                                 :key="index"
                                 :title="$t($enumLangkey('agentOrderLogisticsType', value))"
                                 :value="value">
                  {{ $t($enumLangkey('agentOrderLogisticsType', value)) }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-select v-model:value="selectedDeliveryWay"
                        :allowClear="true"
                        :placeholder="$t('logistics.delivery_way')"
                        style="width: 250px;">
                <a-select-option v-for="(value,key,index) in deliveryWay"
                                 :key="key"
                                 :title="$t($enumLangkey('deliveryWay',value))"
                                 :value="value"
                                 :data-index="index">
                  {{$t($enumLangkey('deliveryWay',value))}}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <SearchProduct ref="searchProductRef"></SearchProduct>
            </a-col>
            <a-col>
              <a-select v-model:value="selectedSourceSales"
                        :allowClear="true"
                        :placeholder="$t('logistics.source_of_sales')"
                        optionFilterProp="search-key"
                        show-search
                        style="width: 250px;">
                <a-select-option v-for="item in sourceSales"
                                 :key="item.id"
                                 :title="item.name"
                                 :search-key="item.name"
                                 :value="item.name">
                  {{item.name}}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-select v-model:value="selectedOrderStatus"
                        :allowClear="true"
                        :placeholder="$t('logistics.status')"
                        style="width: 250px;">
                <a-select-option v-for="(value,key) in agentOrderStatus"
                                 :key="key"
                                 :title="$t($enumLangkey('agentOrderStatus',value))"
                                 :value="value">
                  {{$t($enumLangkey('agentOrderStatus',value))}}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-select v-model:value="isRefund"
                        :allowClear="true"
                        :placeholder="$t('warehouse.whether_to_refund')"
                        style="width: 250px;">
                <a-select-option v-for="(item, index) in refundAndCompensationStatusList"
                                 :key="index"
                                 :title="$t(item.label)"
                                 :value="item.value"
                                 :data-index="index">
                  {{ $t(item.label) }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-select v-model:value="isLoseMoney"
                        :allowClear="true"
                        :placeholder="$t('warehouse.whether_to_compensate')"
                        style="width: 250px;">
                <a-select-option v-for="(item, index) in refundAndCompensationStatusList"
                                 :key="index"
                                 :title="$t(item.label)"
                                 :value="item.value"
                                 :data-index="index">
                  {{ $t(item.label) }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-range-picker style="width: 250px" v-model:value="dataRange" />
            </a-col>
            <a-col>
              <a-space>
                <a-button type="primary" :loading="listLoading" @click="handleChangeStatus">{{ $t('common.search') }}</a-button>
                <a-button type="ghost" :loading="exportLoading" @click="handleDownloadExcel">{{ $t('common.export') }}</a-button>
                <a-button type="ghost" :loading="exportProductLoading" @click="handleExportProductExcel">{{ $t('warehouse.export_products') }}</a-button>
              </a-space>
            </a-col>
          </a-row>
        </a-col>
        <a-col>
          <a-button 
                    @click="handleBulkImport"
                    >{{$t('common.import')}}</a-button>
          <a-button type="primary" class="ml-3"
                    @click="handleCreateOrder"
                    ghost>{{$t('common.create')}}</a-button>
        </a-col>
      </a-row>
      <div class="inventory-table mt-3">
        <a-table :columns="columns"
                 :data-source="orderList"
                 :scroll="{ x:true,y: wrap.contentHeight - 170}"
                 :pagination="false"
                 size="small"
                 :loading="listLoading"
                 class="product-table">
          <template #orderNo>
            <div>{{ $t("logistics.consignment_order_no") }}</div>
            <div>{{ $t("logistics.relation_order_no") }}</div>
          </template>
          <template #consignmentWarehouse>
            <span>
              {{ $t("logistics.consignment") }}
            </span>
          </template>
          <template #recipients>
            <span>
              {{ $t("logistics.recipients") }}
            </span>
          </template>
          <template #destinationAddress>
            <span>
              {{ $t("logistics.destination_address") }}
            </span>
          </template>
          <template #transportSchemeAndLogisticsTrackNo>
            <div>{{ $t("logistics.transport_scheme") }}</div>
            <div>{{ $t("logistics.logistics_track_no") }}</div>
          </template>
          <template #deliveryMethodTitle>
            <span>{{ $t('logistics.delivery_way') }}</span>
          </template>
          <template #disposeAmount>
            <span>
              {{ $t("logistics.dispose_amount") }}
            </span>
          </template>
          <template #transportCharge>
            <span>
              {{ $t("logistics.transport_charge") }}
            </span>
          </template>
          <template #status>
            <span>
              {{ $t("warehouse.status") }}
            </span>
          </template>
          <template #updateTime>
            <div>{{ $t("warehouse.creation_time") }}</div>
            <div>{{ $t("warehouse.update_time") }}</div>
          </template>
          <template #operate>
            <span>
              {{ $t("warehouse.subsequent_steps") }}
            </span>
          </template>

          <template #orderNoCustom="{ record }">
            <div>
              {{record.orderNo || '-'}}
            </div>
            <div>
              {{record.relationOrderNo || '-'}}
            </div>
          </template>

           <template #product="{ record }">
              <ProductDetails :record="record"></ProductDetails>
          </template>

          <template #destinationAddressCustom="{ record }">
            <span v-if="record.destinationAddress">{{ getAddress(record.destinationAddress) }}</span>
            <span v-else>-</span>
          </template>

          <template #recipientsCustom="{ record }">
            <div>
              {{record.recipientsName || '-'}}
            </div>
            <div>
              {{record.recipientsNumber || '-'}}
            </div>
          </template>

          <template #statusCustom="{ record }">
            <a-space direction="vertical">
              <span>{{ $t($enumLangkey('agentOrderStatus',record.status)) }}</span>
              <!-- 取消发货 -->
              <div v-if="record.isApplyCancelDelivery">
                <a-tag v-if="record.cancelDeliveryType==cancelDeliveryTypeEnum.succeed" color="success">
                  {{$t('warehouse.cancel_shipment_succeed')}}
                </a-tag>
                <a-tag v-else-if="record.cancelDeliveryType==cancelDeliveryTypeEnum.unsuccessful" color="error">
                  {{$t('warehouse.cancel_shipment_unsuccessful')}}
                </a-tag>
                <a-tag v-else-if="!record.cancelDeliveryType" color="processing">
                  {{$t('warehouse.cancel_shipment_processing')}}
                </a-tag>
              </div>
              <!-- 取消销毁 -->
              <div v-if="record.isApplyDestruction">
                <a-tag v-if="record.cancelDeliveryType==cancelDeliveryTypeEnum.succeed" color="success">
                  {{$t('warehouse.cancel_destruction_succeed')}}
                </a-tag>
                <a-tag v-else-if="record.cancelDeliveryType==cancelDeliveryTypeEnum.unsuccessful" color="error">
                  {{$t('warehouse.cancel_destruction_unsuccessful')}}
                </a-tag>
                <a-tag v-else-if="!record.cancelDeliveryType" color="processing">
                  {{$t('warehouse.cancel_destruction_processing')}}
                </a-tag>
              </div>
              <a-tag color="blue" v-if="record.refundMoney > 0">{{ $t('account.refund') }} {{ record.currencySymbol }}{{ $filters.amountToFixed2(record.refundMoney) }}</a-tag>
              <a-tag color="orange" v-if="record.loseMoney > 0">{{ $t('warehouse.compensation') }} {{ record.currencySymbol }}{{ $filters.amountToFixed2(record.loseMoney) }}</a-tag>
            </a-space>
          </template>
          <template #totalAmount="{ record }">
            {{ record.currencySymbol }}{{ $filters.amountToFixed2(record.totalAmount) }}
          </template>

          <template #transportSchemeAndLogisticsTrackNoCustom="{ record }">
            <div v-if="record.logisticsType == agentOrderLogisticsTypeEnum.seLogistics">
              <div>
                {{record.transportScheme}}
              </div>
              <div>
                {{record.LogisticsTrackNo || '-'}}
              </div>
            </div>

            <div v-else-if="record.logisticsType == agentOrderLogisticsTypeEnum.thirdparty">
              <a-row>
                <a-col>
                  <CFileDisplay :fileUrl="record.surfaceUrl"
                                class="mr-1"></CFileDisplay>
                </a-col>
                <a-col>
                  <small>{{$t('warehouse.third_side_list')}}</small>
                </a-col>
              </a-row>
            </div>
            <small v-else-if="record.logisticsType == agentOrderLogisticsTypeEnum.destruction">{{ $t('warehouse.destroy_order') }}</small>
            <span v-else>-</span>
          </template>

          <template #track="{ record }">
                <a-row :gutter="8">
                  <a-col><small style="color: #aaa;">{{ $filters.utcToCurrentTime(record.track?.trackTime) }}</small></a-col>
                  <a-col><small style="color: #aaa;">{{ record.track?.trackRemark }}</small></a-col>
                </a-row>
          </template>

          <template #deliveryMethod="{ record }">
            <span v-if="record.deliveryMethod">
              {{ $t($enumLangkey('deliveryWay', record.deliveryMethod)) }}
            </span>
            <span v-else>-</span>
          </template>

          <template #updateTimeCustom="{ record }">
            <div>{{ $filters.utcToCurrentTime(record.creationTime) }}</div>
            <div>{{ $filters.utcToCurrentTime(record.updateTime) }}</div>
          </template>

          <template #operateCustom="{ record }">
            <a-dropdown-button>
              <router-link :to="{
                name: 'consignment_order_details',
                params: { id: record.key },
              }">
                {{ $t("common.details") }}
              </router-link>
              <template #overlay>
                <a-menu @click="handleMenuClick">
                  <a-menu-item :key="1" :record="record" :disabled="!record.isShowCancel">
                    {{$t('warehouse.cancel_order')}}
                  </a-menu-item>
                  <a-menu-item :key="2" :record="record" v-if="record.logisticsType !== agentOrderLogisticsTypeEnum.destruction" :disabled="!record.isShowApplyCancelDelivery">
                    {{$t('warehouse.cancel_shipment')}}
                  </a-menu-item>
                  <a-menu-item :key="3" :record="record" v-if="record.logisticsType !== agentOrderLogisticsTypeEnum.destruction">
                    {{ $t("warehouse.check_the_track") }}
                  </a-menu-item>
                  <a-menu-item :key="4" :record="record" v-if="record.logisticsType === agentOrderLogisticsTypeEnum.destruction" :disabled="!record.isShowApplyCancelDestruction">
                    {{ $t("warehouse.cancel_destruction") }}
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown-button>
          </template>
        </a-table>
      </div>
      <CTrackInfoModal
        v-model:visible="trackModal.visible"
        :list="trackModal.list"
        :loading="trackModal.loading"
      >
        <template v-slot:numberName>
          {{ $t("logistics.consignment_order_no") }}
        </template>
        <template v-slot:no>
          {{trackModal.orderNo}}
        </template>
      </CTrackInfoModal>

    </template>
    <template #contentFooter>
      <a-row type="flex"
             justify="space-around"
             align="middle">
        <a-col>
          <CPager @handlePage="handlePage"
                  :page-data="pageData"></CPager>
        </a-col>
      </a-row>
    </template>
  </Content>
</template>

<script>
import { reactive, toRefs, onMounted, onActivated, ref, } from "vue";
import {
  Row, Col, Popconfirm, Menu,
  Table, Input, Dropdown, Tag,
  Select, Button, Form, message, DatePicker, Space, Modal
} from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import CPager from "@/views/components/CPager.vue";
import SearchProduct from '@/views/components/SearchProduct.vue';
import CFileDisplay from "@/views/components/CFileDisplay.vue"
import CPlanNumberItem from "@/views/components/CPlanNumberItem.vue";
import CTrackInfoModal from "@/views/components/CTrackInfoModal.vue";
import ProductDetails from "../components/ProductDetails.vue";
import { useI18n } from "vue-i18n/index";
import { useRouter } from 'vue-router';
import { useStore } from "vuex";
import { downloadFile } from "@/utils/downloader"
import { getName, getAddressByLanguageV2, currentTimeToUtc, roundNumber, dateString } from "@/utils/general"
import { getCountrys, getOptionWarehouses } from "@/api/modules/common/index";
import {
  getTrackList,
  getOrderList,
  getSourceSales,
  cancelOrder,
  cancelShipment,
  exportAgentOrder,
  exportAgentOrderSku,
  cancelDestruction,
} from "@/api/modules/consignment/index";
import {
  agentOrderStatus,
  deliveryWay,
  cancelDeliveryType as cancelDeliveryTypeEnum,
  agentOrderLogisticsType as agentOrderLogisticsTypeEnum
} from "@/enum/enum.json";

export default ({
  name: "consignment_order_list",
  components: {
    ProductDetails,
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AInput: Input,
    APopconfirm: Popconfirm,
    AInputSearch: Input.Search,
    ASelect: Select,
    AButton: Button,
    ARangePicker: DatePicker.RangePicker,
    ASelectOption: Select.Option,
    AFormItem: Form.Item,
    AForm: Form,
    ADropdown: Dropdown,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    ATag: Tag,
    ASpace: Space,
    AModal: Modal,
    Content,
    CPager,
    CPlanNumberItem,
    CFileDisplay,
    SearchProduct,
    CTrackInfoModal,
  },
  setup () {
    const router = useRouter();
    const { getters } = useStore();
    const i18n = useI18n({ useScope: "global" });

    const refPage = ref();
    const searchProductRef = ref(null);

    //#region columns
    const columns = [
      {
        fixed: "left",
        width: 130,
        slots: {
          title: "orderNo",
          customRender: "orderNoCustom"
        },
      },
      {
        width: 100,
        dataIndex: "consignmentWarehouse",
        slots: {
          title: "consignmentWarehouse",
        },
      },
      {
        width: 130,
        title: () => i18n.t("logistics.product"),
        slots: {
          customRender: "product"
        },
      },
      {
        width: 100,
        slots: {
          title: "recipients",
          customRender: "recipientsCustom"
        },
      },
      {
        width: 250,
        slots: {
          title: "destinationAddress",
          customRender: "destinationAddressCustom"
        },
      },
      {
        width: 150,
        slots: {
          title: "transportSchemeAndLogisticsTrackNo",
          customRender: "transportSchemeAndLogisticsTrackNoCustom"
        },
      },
       {
        width: 200,
        title: () => i18n.t("warehouse.turn_single_track"),
        slots: {
          customRender: "track",
        },
      },
      {
        width: 100,
        slots: {
          title: "deliveryMethodTitle",
          customRender: "deliveryMethod",
        },
      },
      {
        width: 80,
        dataIndex: "disposeAmount",//处理费用
        slots: {
          title: "disposeAmount",
        },
      },
      {
        width: 80,
        dataIndex: "transportCharge",//运输费用
        slots: {
          title: "transportCharge",
        },
      },
      {
        width: 80,
        title: () => i18n.t('warehouse.total_cost'),
        slots: {
          customRender: "totalAmount"
        },
      },
      {
        width: 150,
        slots: {
          title: "status",
          customRender: "statusCustom"
        },
      },
      {
        width: 100,
        slots: {
          title: "updateTime",
          customRender: "updateTimeCustom"
        },
      },
      {
        width: 120,
        fixed: "right",
        slots: {
          title: "operate",
          customRender: "operateCustom",
        },
      },
    ];

    //#endregion
    const state = reactive({
      listLoading: false,
      exportLoading: false,
      exportProductLoading: false,
      countrys: [],
      agentOrderStatus: agentOrderStatus,
      sourceSales: [],
      deliveryWay: deliveryWay,
      refundAndCompensationStatusList: [
        {
          label: 'common.yes',
          value: 1
        },
        {
          label: 'common.no',
          value: 0
        },
      ]
    });

    const data = reactive({
      agentOrderLogisticsTypeEnum,
      selectedOrderStatus: null,
      selectedCountrys: null,
      selectedConsignmentNo: null,
      logisticsType: null,
      selectedDeliveryWay: null,
      selectedSourceSales: null,
      isRefund: null,
      isLoseMoney: null,
      searchKey: "",
      orderList: [],
      dataRange: [],
      productWarehouseStatus: [],
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10
      },
    });

    const handlePage = async (pageData) => {
      data.pageData.skipCount = pageData.skipCount;
      data.pageData.maxResultCount = pageData.maxResultCount;
      handleSearch();
    }

    const handleChangeStatus = () => {
      data.pageData.skipCount = 0;
      data.pageData.currentIndex = 1;
      handleSearch();
    }

    const handleCreateOrder = async () => {
      router.push({ name: "consignment_order_create" });
    }

   const handleBulkImport=()=>{
      router.push({ name: "consignment_order_bulk_import" });
   }

    const getAddress = (address) => {
      return getAddressByLanguageV2(address, getters.language);
    }

    const getSearchData = () => {
      let beginTime, endTime;
      if (data.dataRange.length > 0) {
        beginTime = currentTimeToUtc(data.dataRange[0]?.format('YYYY-MM-DD 00:00:00'));
        endTime = currentTimeToUtc(data.dataRange[1]?.format('YYYY-MM-DD 00:00:00'));
      }
      let productId = searchProductRef.value.selectedProductId || null;
      return {
        "purposeCountryId": data.selectedCountrys,
        "warehouseId": data.selectedConsignmentNo,
        "logisticsType": data.logisticsType,
        "saleSourceName": data.selectedSourceSales,
        "status": data.selectedOrderStatus,
        "deliveryMethod": data.selectedDeliveryWay,
        "productId": productId,
        "beginTime": beginTime,
        "endTime": endTime,
        "searchKey": data.searchKey,
        "isRefund": data.isRefund === 1 ? true : data.isRefund === 0 ? false : null,
        "isLoseMoney": data.isLoseMoney === 1 ? true : data.isLoseMoney === 0 ? false : null,
      };
    }

    const handleSearch = async () => {
      const searchData = Object.assign({}, data.pageData, getSearchData())

      state.listLoading = true;

      getOrderList(searchData)
        .then((res) => {
          if (res.result) {
            let { items, totalCount } = res.result;
            data.orderList = items.map(x => {
              return {
                key: x.id,
                id: x.id,
                orderNo: x.orderNo,
                relationOrderNo: x.relationOrderNo,
                consignmentWarehouse: x.warehouseNo,
                recipientsName: x.receiptUserName,
                recipientsNumber: x.contactPhone,
                destinationAddress: x.receiveAddressData,
                transportScheme: x.transportScheme,
                LogisticsTrackNo: x.logisticsTrackNo,
                logisticsType: x.logisticsType,
                surfaceUrl: x.surfaceUrl?.length > 0 ? x.surfaceUrl[0] : null,
                disposeAmount: x.currencySymbol + roundNumber(x.processTotalAmount),
                transportCharge: x.currencySymbol + roundNumber(x.freightTotal),
                status: x.status,
                track: x.track,
                products: x.products,
                creationTime: x.createTime,
                totalAmount: x.totalAmount,
                currencySymbol: x.currencySymbol,
                updateTime: x.updateTime,
                //是否能取消发货
                isShowApplyCancelDelivery: x.isShowApplyCancelDelivery,
                //是否 申请了 取消发货
                isApplyCancelDelivery: x.isApplyCancelDelivery,
                //点了取消发货的结果：（null 处理中，true 取消发货-成功 false 取消发货-失败）
                cancelDeliveryType: x.cancelDeliveryType,
                // 是否能取消销毁
                isShowApplyCancelDestruction: x.isShowApplyCancelDestruction,
                // 申请取消发货标识
                isApplyDestruction: x.isApplyDestruction,
                refundMoney: x.refundMoney,
                loseMoney: x.loseMoney,
                isShowCancel: x.isShowCancel,
                // 派送方式
                deliveryMethod: x.deliveryMethod,
              }
            })
            data.pageData.totalCount = parseInt(totalCount);
          } else {
            data.pageData.totalCount = 0;
          }
          state.listLoading = false;
        })
        .catch(() => {
          state.listLoading = false;
        });
    }

    const funcSourceSales = async () => {
      getSourceSales().then((res) => {
        state.sourceSales = res.result;
      })
    }

    const handleCancelOrder = (record) => {
      Modal.confirm({
        title: i18n.t("common.operation"),
        content: i18n.t("warehouse.are_you_sure_cancel_order"),
        okText: i18n.t("common.confirm"),
        cancelText: i18n.t("common.cancel"),
        onOk: () => {
          return cancelOrder({ id: record.key }).then(() => {
            handleSearch();
            message.success(i18n.t("common.succeed"));
          });
        },
      });
    }

    const handleCancelShipment = (record) => {
      Modal.confirm({
        title: i18n.t("common.operation"),
        content: i18n.t("warehouse.are_you_sure_cancel_shipment"),
        okText: i18n.t("common.confirm"),
        cancelText: i18n.t("common.cancel"),
        onOk: () => {
          return cancelShipment(record.key).then(() => {
            handleSearch();
            message.success(i18n.t("common.succeed"));
          });
        },
      });
    }

    const handleCancelDestruction = (record) => {
      Modal.confirm({
        title: i18n.t("common.operation"),
        content: i18n.t("warehouse.are_you_sure_cancel_destruction"),
        okText: i18n.t("common.confirm"),
        cancelText: i18n.t("common.cancel"),
        onOk: () => {
          return cancelDestruction({ id: record.key }).then(() => {
            handleSearch();
            message.success(i18n.t("common.succeed"));
          });
        },
      });
    }

    const handleGetCountrys = async () => {
      getCountrys().then((res) => {
        state.countrys = res.result;
      })
    }

    const getLanguageName = (item) => {
      return getName(item, getters.language);
    }

    const funcWarehouses = () => {
      getOptionWarehouses({ warehouseType: 2 }).then((res) => {
        if (res.result) {
          data.productWarehouseStatus = res.result;
        }
      }).finally()
    };

    // 导出订单列表
    const handleDownloadExcel = () => {
      state.exportLoading = true
      let url = exportAgentOrder();
      const data = Object.assign({}, getSearchData())
      downloadFile(url, `order_task_${dateString()}.csv`, "POST", data).then(() => {
        message.success(i18n.t("common.succeed"))
      }).catch(() => { }).finally(() => {
        state.exportLoading = false
      })
    }

    // 导出产品产品
    const handleExportProductExcel = () => {
      state.exportProductLoading = true
      let url = exportAgentOrderSku();
      const data = Object.assign({}, getSearchData())
      downloadFile(url, `order_task_product_${dateString()}.csv`, "POST", data).then(() => {
        message.success(i18n.t("common.succeed"))
      }).catch(() => { }).finally(() => {
        state.exportProductLoading = false
      })
    }

    const handleMenuClick = (e) => {
      let { key, item } = e;
      let { record } = item;
      
      switch (key) {
        case 1:
          // 取消订单
          handleCancelOrder(record);
          break;
        case 2:
          // 申请取下发货
          handleCancelShipment(record);
          break;
        case 3:
          // 查看轨迹
          handleShowTrackModal(record);
          break;
        case 4:
          // 申请取消销毁
          handleCancelDestruction(record);
          break;
        default:
          break;
      }
    }

    const trackModal = reactive({
      visible:false,
      loading: false,
      list: [],
      orderId:null,
      orderNo:null
    })
    
    const handleShowTrackModal = (record) => {
      trackModal.visible = true;
      trackModal.orderId = record.id;
      trackModal.orderNo = record.orderNo;
      funcGetTrackInfo();
    };

    const funcGetTrackInfo = () => {
      trackModal.visible = true;
      trackModal.loading = true;
      getTrackList({ ...trackModal })
        .then(({ result }) => {
          trackModal.list = result ?? [];
        })
        .finally(() => {
          trackModal.loading = false;
        });
    };

    onMounted(async () => {
      handleGetCountrys();
      funcSourceSales();
      funcWarehouses();
    })

    onActivated(async () => {
      handleSearch();
    })

    return {
      ...toRefs(state),
      ...toRefs(data),
      columns,
      refPage,
      cancelDeliveryTypeEnum,
      searchProductRef,

      handlePage,
      handleSearch,
      handleCreateOrder,
      getLanguageName,
      handleCancelOrder,
      getAddress,
      handleChangeStatus,
      handleCancelShipment,
      handleBulkImport,
      handleDownloadExcel,
      handleExportProductExcel,

      handleShowTrackModal,
      trackModal,
      handleMenuClick,
    };
  }
})
</script>

<style lang="less" scoped>
</style>