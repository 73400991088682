<template>
  <a-row type="flex" justify="space-between" align="middle">
    <template v-for="(item, index) in record.products" :key="index">
      <a-col v-if="index <= 1"> {{ item.seSku }} x {{ item.outCount }} </a-col>
    </template>
  </a-row>
  <a-row type="flex" justify="space-between" align="middle">
    <a-col>
      <span v-if="record.products?.length > 2">...</span>
    </a-col>
    <a-col>
      <a-button type="link" @click="handleShowProductsModal">{{
        $t("common.details")
      }}</a-button>
    </a-col>
  </a-row>

  <a-modal
    width="900px"
    :visible="state.visible"
    :title="$t('common.details')"
    :cancelButtonProps="{ style: { display: 'none' } }"
    :centered="true"
    @ok="close"
    @cancel="close"
  >
    <a-table
      :columns="columns"
      :data-source="record.products"
      :pagination="false"
      :scroll="{ x: 500, y: 500 }"
      :rowKey="
        (record, index) => {
          return index;
        }
      "
      size="small"
    >
      <template #product_info="{ record }">
        <a-space>
          <div class="table-list-img-common">
            <c-image :src="record.imgUrl" />
          </div>
          <div>
            <div>{{ record.name }}</div>
            <div>{{ record.no }}</div>
          </div>
        </a-space>
      </template>

      <!-- <template #footer>
        <a-row justify="end">
          <a-col>
            <a-space :size="50">
              <span>{{ $t('warehouse.product') }}: {{ totalProduct }}</span>
              <span>{{ $t('common.total_product') }}: {{ totalProductNum }}</span>
            </a-space>
          </a-col>
        </a-row>
      </template> -->
    </a-table>
  </a-modal>
</template>

<script>
import { defineComponent, reactive } from "vue";
import { Modal, Space, Table, Row, Col, Button } from "ant-design-vue";
import { useI18n } from "vue-i18n/index";
import CImage from "../../components/CImage.vue";

export default defineComponent({
  name: "ProductDetails",
  components: {
    AModal: Modal,
    ATable: Table,
    ASpace: Space,
    ARow: Row,
    ACol: Col,
    AButton: Button,
    CImage,
  },
  props: {
    // visible: {
    //   type: Boolean,
    //   default: false,
    // },
    record: Object,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });

    const state = reactive({
      visible: false,
    });

    const handleShowProductsModal = () => {
      state.visible = true;
    };

    const columns = [
      {
        width: 400,
        title: () => vueI18n.t("warehouse.product_info"),
        slots: {
          customRender: "product_info",
        },
      },
      {
        title: "SESKU",
        dataIndex: "seSku",
      },
      {
        dataIndex: "outCount",
        width: 100,
        title: () => vueI18n.t("warehouse.product_quantity"),
      },
    ];

    const close = () => {
      state.visible = false;
    };

    return {
      state,
      columns,
      close,
      handleShowProductsModal,
    };
  },
});
</script>
